import { merge } from "lodash";

export const envDefaults = {
  oidc: {
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    tokenManager: {
      expireEarlySeconds: 60
    }
  },
  baseURL: ""
};

export const npdEnvDefaults = merge(envDefaults, {
  oidc: {
    clientId: "0oa9qcnl8vpKCc48C1t7",
    issuer: "https://mlbtest.okta.com/oauth2/ausa3295vsxer28Br1t7"
  }
});
