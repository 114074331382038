export enum AppEnv {
  LOCAL = "local",
  DEV = "dev",
  STG = "stg",
  PROD = "prod"
}

export type EnvProps = {
  appEnv: AppEnv;
  oidc: {
    clientId: string;
    issuer: string;
    redirectUri?: string;
    scopes?: string[];
    pkce?: boolean;
    disableHttpsCheck?: boolean;
    tokenManager?: {
      expireEarlySeconds?: number;
    };
  };
  baseURL: string;
};

declare global {
  interface Window {
    MLBBest: {
      env: EnvProps;
    };
  }
}

export default window.MLBBest?.env;
